<template>
  <v-card min-width="300px" height="350px" flat class="rounded-lg">
    <v-card-title class="text-color-title caption font-weight-bold py-1">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon @click="menuClick()">
        <v-icon>mdi-menu-open</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-date-picker
        v-model="date"
        full-width
        class="date-picker-no-header"
      ></v-date-picker>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    title: "CALENDAR",
    date: new Date().toISOString().substr(0, 10)
  }),
  methods: {
    getData() {},
    menuClick() {
      // alert("click");
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style scoped>
/**
.date-picker-no-header >>> .v-picker__title {
  display: none;
}

.date-picker-no-header >>> .v-btn__content {
  font-size: 12px;
}
 */
</style>
